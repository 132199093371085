import { Col } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import { ImageContent, StyledImage, Wrapper } from './styled';

interface Props {
  imgSrc: string;
  bgColor: string;
  link: string;
  hasDetail: boolean;
}

const ReferenceBlock = ({ imgSrc, bgColor, link, hasDetail }: Props) => {
  const renderInnerPiece = () => (
    <Wrapper showAnimate={hasDetail} style={{ background: bgColor }}>
      <ImageContent>
        <StyledImage src={imgSrc} />
      </ImageContent>
    </Wrapper>
  );

  return (
    <Col style={{ maxWidth: 400 }} xs={24} sm={12} xl={8}>
      {hasDetail ? <Link to={link}>{renderInnerPiece()}</Link> : renderInnerPiece()}
    </Col>
  );
};

export default ReferenceBlock;
