import { Row } from 'antd';
import Footer from 'components/CommonComponents/Footer';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import SEO from 'components/CommonComponents/SEO';
import { TitleText } from 'components/CommonComponents/Titles/styled';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import { MainContainer, Separator } from 'components/PageCareer/styled';
import ReferenceBlock from 'components/ReferenceBlock';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const pageQuery = graphql`
  {
    allProjectlistYaml {
      nodes {
        hasDetail
        slug
        logo {
          publicURL
        }
        color
      }
    }
  }
`;

const Projects = () => {
  const response = useStaticQuery(pageQuery);

  const projects: any[] = response.allProjectlistYaml.nodes;

  return (
    <>
      <SEO title="Projekty" />
      <GlobalStyle />

      <NavigationBar />
      <Separator />
      <MainContainer>
        <StyledSection>
          <TitleText>Reference</TitleText>
          <Row justify="center" align="middle" gutter={[16, 16]} style={{ maxWidth: 1200 }}>
            {projects.map((project, index) => (
              <ReferenceBlock
                key={index}
                hasDetail={project.hasDetail}
                link={`/project/${project.slug}`}
                bgColor={project.color}
                imgSrc={project.logo.publicURL}
              />
            ))}
          </Row>
          <Separator />
        </StyledSection>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Projects;
