import styled, { css } from 'styled-components';

interface WrapperProps {
  showAnimate: boolean;
}

const animation = css`
  &:hover {
    transform: translateY(-10px);
    transition: all 0.5s;
    opacity: 0.8;
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  min-width: 200px;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${({ showAnimate }) => showAnimate && animation}
`;

export const ImageContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-block;
  padding: 30px;
`;

export const StyledImage = styled.img`
  position: absolute;
  padding: 40px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
`;
